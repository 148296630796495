var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "pdf-container" } }, [
    _vm.isLoading
      ? _c(
          "div",
          [
            _c("loader", {
              attrs: {
                object: "#ff9633",
                color1: "#bdc2c5",
                color2: "#ff9633",
                size: "5",
                speed: "2",
                bg: "#343a40",
                objectbg: "#999793",
                opacity: "80",
                name: "circular",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c("div", [
      _c("div", { staticClass: "action-container" }, [
        _c("div", { staticClass: "page-count-container" }, [
          _c(
            "button",
            {
              attrs: {
                disabled: _vm.isPreviousPageDisabled,
                "aria-label": "Previous Page",
              },
              on: { click: _vm.onPreviousPageClicked },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "button-icon",
                attrs: { icon: ["fas", "step-backward"] },
              }),
            ],
            1
          ),
          _c("span", { staticClass: "pages" }, [
            _vm._v(
              " Page " +
                _vm._s(_vm.currentPage) +
                " of " +
                _vm._s(_vm.totalPages) +
                " "
            ),
          ]),
          _c(
            "button",
            {
              attrs: {
                disabled: _vm.isNextPageDisabled,
                "aria-label": "Next Page",
              },
              on: { click: _vm.onNextPageClicked },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "button-icon",
                attrs: { icon: ["fas", "step-forward"] },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "download-container" }, [
          _c(
            "button",
            {
              staticClass: "download",
              attrs: { "aria-label": "Download PDF" },
              on: { click: _vm.downloadPdf },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "button-icon",
                attrs: { icon: ["fas", "download"] },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        [
          _c("VuePdfEmbed", {
            ref: "pdfViewer",
            attrs: {
              source: _vm.pdfSource,
              page: _vm.currentPage,
              "annotation-layer": "",
            },
            on: {
              "internal-link-clicked": _vm.onInternalLinkClicked,
              loaded: _vm.onLoadingCompleted,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }