<template>
  <div id="pdf-container">
    <div v-if='isLoading'>
      <loader object="#ff9633"
        color1="#bdc2c5"
        color2="#ff9633"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="circular">
      </loader>
    </div>
    <div>
      <div class="action-container">
        <div class="page-count-container">
          <button @click="onPreviousPageClicked"
            :disabled="isPreviousPageDisabled"
            aria-label="Previous Page">
            <font-awesome-icon :icon="['fas', 'step-backward']" class="button-icon"/>
          </button>
          <span class="pages"> Page {{ currentPage }} of {{ totalPages }} </span>
          <button @click="onNextPageClicked"
            :disabled="isNextPageDisabled"
            aria-label="Next Page">
            <font-awesome-icon :icon="['fas', 'step-forward']" class="button-icon"/>
          </button>
        </div>
        <div class="download-container">
          <button @click="downloadPdf" class="download" aria-label="Download PDF">
            <font-awesome-icon :icon="['fas', 'download']" class="button-icon"/>
          </button>
        </div>
      </div>
      <div>
        <VuePdfEmbed
          ref="pdfViewer"
          :source="pdfSource"
          :page="currentPage"
          annotation-layer
          @internal-link-clicked="onInternalLinkClicked"
          @loaded="onLoadingCompleted">
        </VuePdfEmbed>
      </div>
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';


export default {
  data() {
    return {
      isLoading: true,
      fileName: '2024_ETB_Digital_Inspiration_Guide_Warm_PreWelcome_ULL_v2.pdf',
      currentPage: 1,
      totalPages: 1,
    };
  },
  components: { VuePdfEmbed },
  computed: {
    pdfSource() {
      return `files/${this.fileName}`;
    },
    isPreviousPageDisabled() {
      return this.currentPage === 1;
    },
    isNextPageDisabled() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    downloadPdf() {
      this.$refs.pdfViewer.download();
    },
    onInternalLinkClicked(pageNumber) {
      this.currentPage = pageNumber;
    },
    onLoadingCompleted() {
      this.totalPages = this.$refs.pdfViewer.pageCount;
      this.isLoading = false;
    },
    onPreviousPageClicked() {
      this.currentPage -= 1;
    },
    onNextPageClicked() {
      this.currentPage += 1;
    },
  },
};
</script>

<style scoped>

  #pdf-container {
    height: 100%;
    background-color: #ddd;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .action-container {
    padding: 5px;
    background-color: #304048;
    display: flex;
  }

  .page-count-container {
    width: 60%;
  }

  .download-container {
    width: 40%;
    display: flex;
    justify-content: flex-end;
  }

  button {
    background-color: #ddd;
    color: #222;
    min-height: 30px;
    min-width: 30px;
  }

  .button-icon {
    color: #222;
  }
  .pages {
    color:#fff
  }

  .download {
    margin-left: auto;
  }
</style>
